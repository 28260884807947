<template>
    <div class="mui-content">
        <div class="product-body" :style="`height: ${height}px;`" v-if="orderInfo">
            <div class="order-reminder" v-if="orderReminder">
                <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20201125161405322.png"/>
                <div class="ivv-txt">{{orderReminder}}</div>
            </div>
            <div class="mui-table-view address">
                <div class="point"><span class="icon-location"></span></div>
                <div class="info">
                    <span>收货人: {{ orderInfo.name }}</span>
                    <span class="mi-pull-right">{{ orderInfo.phone }}</span>
                </div>
                <div class="detail">
                    <span>收货地址: {{ orderInfo.fullAddress }}</span>
                </div>
            </div>
            <van-cell-group class="pay-box">
                <van-cell class="pay-type" title="支付方式">
                    <van-radio-group v-model="payType">
                        <van-radio :name="item.id" v-for="item in payList" key="index">{{item.name}}</van-radio>
                    </van-radio-group>
                </van-cell>
            </van-cell-group>
            <div class="product-list">
                <div class="brand-box" style="border-bottom: 1px solid #eee;">
                    <label>兑换商品</label>
                </div>
                <ul class="mi-table-view">
                    <li class="mi-table-view-cell" v-for="product in orderInfo.goodsList"
                        :class="{'unsold':product.goodsState && product.goodsState.layerImgUrl}">
                        <div class="prompt" v-if="product.goodsState && product.goodsState.layerImgUrl">
                            <img :src="product.goodsState.layerImgUrl"/>
                        </div>
                        <img class="mi-media-object mui-pull-left" v-lazy="product.goodsImg">
                        <div class="mi-media-body">
                            <div class="pro-name mui-ellipsis" style="font-size: 15px;font-weight: bold">{{product.goodsName}}</div>
                            <div class="format mui-ellipsis" style="font-size: 15px;margin-top: 4px">{{product.goodsSpecifications}}</div>
                            <div class="format mui-ellipsis" style="color: #999;font-size: 12px;margin-top: 4px">{{product.goodsActEffect}}</div>
                            <div class="priceBox">
                                <span>¥</span><span class="price">{{product.goodsPrice}}</span>
                                <div class="number">x{{product['goodsCount']}}</div>
                            </div>
                        </div>
                    </li>
                </ul>
                <van-cell-group class="order-info">
                    <van-cell title="邮费" :value="'￥' + orderInfo.postage"/>
                    <van-cell title="兑换红包">
                        <span class="font-pink">抵扣￥{{ orderInfo.redBagBalance}}</span>
                    </van-cell>
                    <van-field v-model="buyerMessage" label="买家留言" placeholder="买家的留言备注"></van-field>
                </van-cell-group>
            </div>
        </div>
        <div class="footer" v-if="orderInfo">
            <van-row class="mui-bar-tab" type="flex" justify="end">
                <van-col span="14">
                    <span class="count">共{{orderInfo.Total}}件</span>
                    <span class="txt">待付款:</span>
                    <!--                    <span class="font-pink"></span>-->
                    <span class='price font-pink'>¥{{orderInfo.payAmount}}</span>
                </van-col>
                <van-col span="8">
                    <van-button type="default" @click="toPay">提交订单</van-button>
                </van-col>
            </van-row>
        </div>
    </div>
</template>

<script>
  export default {
    name: "Confirm",
    data() {
      return {
        goodList: '', //下单的商品
        addressInfo: null,
        orderInfo: null,
        buyerMessage: '', //买家留言
        payType: 1, //支付方式    0:线下支付 1在线支付(余额支付) 3在线支付(微信支付)
        payList: [{name: "在线支付", id: 1}],
        orderReminder: '', //下单提醒
        isRepeat: false,
        height: ''
      }
    },
    created() {
      let {goodList} = this.$route.query;
      this.goodList = goodList;
      this.getShopCartList();
      this.height = document.documentElement.clientHeight
    },
    methods: {
      getShopCartList: function () {
        this.$toast.loading({message: '加载中...', duration: 0, overlay: true});
        this.post("/OrderGoods/FixedExchange/ConfirmFixedExchangeOrder", {
          goodsList: this.goodList
        }, true, 2).then(res => {
          this.$toast.clear();
          if (res.code === 1) {
            let data = res.response;
            if (!data.fullAddress) {
              return this.$dialog.alert({message: '蜜蛋小姐姐提示您，还未填写地址,点击前往', title: '提示'}).then(() => {
                window.location.href = this.baseHost + "/7999/Arongleweb/html/groupon/address_edit.html?orderType=10&param=" + this.goodList;
              });
            }
            let total = 0;
            data.goodsList.forEach(it => {
              total += it.goodsCount;
            })
            data.Total = total;

            this.orderInfo = data;
              console.log(this.orderInfo)
          }
        });

      },
      toPay() {
        this.$toast.loading({message: '订单生成中...', duration: 0, overlay: true});
        this.post("/OrderGoods/FixedExchange/CreateFixedExchangeOrderV2", {
          goodsList: this.goodList,
          sourceType: 2,
          remark: this.buyerMessage
        }, true, 2).then(res => {
          this.$toast.clear();
          if (res.code === 1) {
            window.localStorage.cartListStr = ""
            this.$dialog.confirm({
              title: '恭喜您',
              message: '下单成功',
              confirmButtonText: '去支付',
              cancelButtonText: '继续兑换'
            }).then(() => {
              let {orderId} = res.response;
              this.$router.replace("/Recharge/order_detail?orderId=" + orderId + "&orderType=10")
            }).catch(() => {
              this.$router.back();
            });
          } else {
            this.$dialog({
                title: '提示',
                message: res.msg,
                confirmButtonColor: '#ff98a0'
            })
          }
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
    .product-body {
        padding: 11px 15px 84px;
        text-align: left;
        background-color: #f8f8f8;
        box-sizing: border-box;
        overflow-y: scroll;

        .order-reminder {
            display: flex;
            margin: -11px -15px 5px;
            padding: 11px 15px;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            align-items: center;
            background-color: #fff;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);

            img {
                width: 43px;
                margin-right: 12px;
            }

            .ivv-txt {
                color: #ff98a0;
            }
        }

        .address {
            position: relative;
            border-radius: 6px;
            padding: 17px 15px 17px 58px;
            background-color: #fff;

            .point {
                position: absolute;
                top: 50%;
                left: 11px;
                width: 33px;
                height: 33px;
                text-align: center;
                margin-top: -16px;
                border-radius: 50%;
                background-color: #ffb5bb;

                .icon-location {
                    color: #fff;
                    font-size: 20px;
                    line-height: 33px;

                    &:before {
                        content: "\e9f0";
                    }
                }
            }

            .info {
                span {
                    color: #666;
                }

                .mi-pull-right {
                    float: right;
                }
            }

            .detail {
                padding-top: 3px;

                span {
                    color: #999;
                }
            }
        }


        .pay-box {
            overflow: hidden;
            margin-top: 10px;
            border-radius: 6px;

            .pay-type {
                display: block;

                .van-cell__title {
                    width: 80px;
                    display: inline-block;
                }

                .van-cell__value {
                    width: calc(100% - 80px);
                    display: inline-block;

                    .van-radio-group {
                        text-align: right;

                        /deep/ .van-radio {
                            display: inline-block;
                            vertical-align: middle;

                            &:nth-child(2n) {
                                margin-left: 20px;
                            }

                            .van-radio__icon {
                                display: inline-block;
                                vertical-align: sub;
                            }

                            .van-radio__icon--checked {
                                .van-icon {
                                    color: #fff;
                                    border-color: #ff98a0;
                                    background-color: #ff98a0;
                                }
                            }
                        }
                    }
                }

                span {
                    color: #666;
                }
            }
        }

        .product-list {
            margin-top: 10px;

            .brand-box {
                padding: 11px 15px;
                border-radius: 6px 6px 0 0;
                background-color: #fff;

                label {
                    color: #666;
                    font-size: 14px;
                }
            }

            .mi-table-view {
                .mi-table-view-cell {
                    width: 100%;
                    display: flex;
                    padding: 10px 15px;
                    position: relative;
                    background-color: #fff;
                    box-sizing: border-box;

                    .mi-media-object {
                        width: 110px;
                        height: 110px;
                        float: left;
                        margin-right: 10px;
                    }

                    .mi-media-body {
                        height: 100%;
                        overflow: hidden;
                        padding-top: 10px;

                        .pro-name {
                            color: #636363;
                            font-size: 14px;
                        }

                        .format {
                            color: #636363;
                            line-height: 22px;
                        }

                        .para {
                            color: #999;
                        }

                        .priceBox {
                            position: absolute;
                            left: 136px;
                            right: 15px;
                            bottom: 11px;

                            span {
                                color: #ff98a0;
                                font-size: 14px;
                            }

                            .price {
                                font-size: 18px;
                            }

                            .number {
                                float: right;
                                color: #b5b5b5;
                                font-size: 14px;
                            }
                        }
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 30px;
                        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAA9AgMAAAD69FDPAAAACVBMVEUAAAAAAAAAAACDY+nAAAAAA3RSTlMIAQTqE2A+AAAAGklEQVQI12MIIAImMExgWADCeGADECowkAAAXQURodU+YFsAAAAASUVORK5CYII=") repeat-x;
                        background-size: auto 100%;
                    }

                    &:last-child {
                        border-radius: 0 0 6px 6px;
                    }
                }
            }

            .van-cell-group {
                overflow: hidden;
                margin-top: 10px;
                border-radius: 6px;

                .subtitle {
                    overflow: hidden;
                    padding: 15px 15px 3px;

                    .text {
                        color: #ff98a0;
                        font-size: 10px;
                        padding: 0 5px;
                        border-radius: 18px;
                        border: 1px solid #ff98a0;
                        display: inline-block;
                        //transform: scale(.6);
                        //-webkit-transform-origin-x: 0;
                    }
                }

                .van-cell {
                    .van-cell__title {
                        > span {
                            color: #666;
                        }
                    }

                    .van-cell__value {
                        .font-pink {
                            color: #ff98a0;
                        }
                    }
                }

                .reCommend {
                    position: relative;
                    padding: 0 15px 10px;

                    .van-cell {
                        padding: 10px 0;

                        .van-cell__value {
                            span {
                                color: #666;
                            }
                        }

                        &:after {
                            border-width: 0;
                        }
                    }

                    .van-swipe-box {
                        position: absolute;
                        top: 8px;
                        right: 0;

                        .van-switch--on {
                            background-color: #ff98a0;
                        }

                        .van-switch__node {
                            font-size: 24px;
                        }

                        .board-txt {
                            display: inline-block;
                            vertical-align: top;
                        }
                    }

                    //.van-switch {
                    //  position: absolute;
                    //  top: 0;
                    //  right: 0;
                    //  .van-switch__node {
                    //    font-size: 22px;
                    //  }
                    //}
                    .van-loading {
                        line-height: 110px;
                        display: inline-block;
                    }

                    .agent-info {
                        height: 110px;
                        padding: 10px 15px;
                        text-align: center;
                        box-sizing: border-box;
                        background-color: #f8f8f8;

                        .mui-input-row {
                            padding: 3px 0;
                            text-align: left;

                            label {
                                color: #999;
                                width: 65px;
                                display: inline-block;
                            }

                            .value {
                                color: #666;
                            }
                        }
                    }
                }
            }

            .order-info {
                margin-top: 10px;

                .van-cell {
                    .van-cell__title {
                        span {
                            color: #666;
                        }
                    }

                    &.order-prompt {
                        .van-cell__value {
                            color: #ff98a0;
                        }
                    }

                    &:after {
                        border-color: #f1f1f1;
                        transform: scaleY(1);
                        -webkit-transform: scaleY(1);
                    }
                }

            }
        }

        &.pay-box-fixed {
            .pay-box {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                z-index: 99;
                margin-top: 0;
                border-radius: 0;
                border-bottom: 1px solid #f1f1f1;
            }

            .product-list {
                padding-top: 44px;
            }
        }
    }

    .mui-content {
        /*padding-bottom: 84px;*/

        .footer {
            .mui-bar-tab {
                position: fixed;
                left: 0;
                right: 0;
                bottom: 0;
                height: 44px;
                z-index: 100;
                padding: 0 15px;
                border-top: 1px solid #f1f1f1;
                background-color: #fff;

                .van-col {
                    display: flex;
                    font-size: 12px;
                    align-items: center;
                    letter-spacing: 1px;
                    justify-content: flex-end;

                    .count {
                        color: #b5b5b5;
                        padding-right: 5px;
                    }

                    .txt {
                        color: #666;
                        font-size: 14px;
                    }

                    .price {
                        font-size: 18px;
                        margin-top: 5px;
                    }

                    .van-button {
                        height: 33px;
                        color: #fff;
                        padding: 0 18px;

                        line-height: 30px;
                        border-radius: 33px;
                        background-color: #ff98a0;

                        span {
                            line-height: 31px;
                        }

                        &.van-button--disabled {
                            opacity: 1;
                            background-color: #ccc;
                        }
                    }
                }
            }
        }

        .van-checkbox__icon--checked {
            .van-icon {
                border-color: #ff98a0;
                background-color: #ff98a0;
            }
        }

        .font-pink {
            color: #ff98a0;
        }

        .mui-ellipsis {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis
        }
    }

    .iphoneX {
        .mui-content {
            /*padding-bottom: 100px;*/

            .footer {
                .mui-bar-tab {
                    padding-bottom: 30px;
                }
            }
        }
    }

    .van-button {
        &:active:before {
            opacity: 0;
        }
    }


    .icon--17:before {
        content: "\e9b4";
    }

    .icon--4:before {
        content: "\e9c5";
    }

    @media screen and (max-height: 500px) {
        .mui-backNone .rop-btn {
            padding-top: 30%
        }
    }

    @media screen and (max-width: 360px) {
        .self-cont .self-prod li .prod-cont .mui-numbox {
            width: 90px;
            padding: 0 28px;
        }

        .self-cont .self-prod li .prod-cont .mui-btn {
            width: 28px;
        }
    }

</style>